/* eslint-disable no-nested-ternary */
import { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Slide from '@material-ui/core/Slide';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import ArrowIcon from '@material-ui/icons/ArrowBackIos';
import Fade from '@material-ui/core/Fade';
import Grow from '@material-ui/core/Grow';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import { makeStyles } from '@material-ui/styles';
import AntrosportLogo, { IsotipoAntrosport } from '../shared/Components/AntrosportLogo';
import { useBreakpoint, useScrollPosition } from '../shared/tools';
import { SidebarContext } from '../shared/Contexts';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    padding: '15px 35px',
    backgroundColor: '#ffffff',
  },
  toolbarMobile: {
    backgroundColor: '#ffffff',
    height: 64,
  },
  antrosportLogo: {
    cursor: 'pointer',
    [theme.breakpoints.down('lg')]: {
      width: '250px',
    },
  },
  link: {
    fontWeight: 600,
    padding: '10px',
    minWidth: '100px',
  },
  gridContainerLinks: {
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-end',
    },
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
    },
  },
  gridContainerLogo: {
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-start',
    },
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
    },
  },
}));

const isLinkActive = (href) => {
  const actualPath = window.location.pathname;
  return href === actualPath;
};

export const ButtonGroup = (props) => (
  <Box
    display="flex"
    alignItems="center"
    justifyContent="space-around"
    width="300px"
    {...props}
  >
    <Button
      variant="contained"
      disableElevation
      color="secondary"
      style={{
        width: '130px',
      }}
      onClick={() => {
        window.location.href = `https://app.antrosport.com/?suscription=${true}`;
      }}
    >
      Registrate
    </Button>
    <Button
      variant="outlined"
      color="default"
      endIcon={<ArrowIcon style={{ transform: 'rotate(180deg)' }} />}
      style={{
        width: '130px',
      }}
      onClick={() => {
        window.location.href = 'https://app.antrosport.com/';
      }}
    >
      Ingresar
    </Button>
  </Box>
);

// eslint-disable-next-line react/prop-types
const TopbarContentLogo = () => {
  const history = useHistory();
  const classes = useStyles();
  const [, , sections] = useContext(SidebarContext);

  const handleClickLink = (e, href) => {
    e.preventDefault();
    history.push(href);
  };

  return (
    <Grow in timeout={800}>
      <Grid container spacing={4}>
        <Grid item container sm={12} lg={2} className={classes.gridContainerLogo}>
          <AntrosportLogo
            className={classes.antrosportLogo}
            onClick={() => history.push('/')}
          />
        </Grid>
        <Grid
          item
          container
          sm={12}
          lg={10}
          className={classes.gridContainerLinks}
        >
          {sections.map(({ href, label }) => (
            label && (
              <Link
                key={href}
                color={isLinkActive(href) ? 'secondary' : 'textPrimary'}
                href={href}
                onClick={(e) => handleClickLink(e, href)}
                className={classes.link}
              >
                {label}
              </Link>
            )
          ))}
          <ButtonGroup />
        </Grid>
      </Grid>
    </Grow>
  );
};

const TopbarContent = () => {
  const classes = useStyles();
  const history = useHistory();
  const [, , sections] = useContext(SidebarContext);

  const handleClickLink = (e, href) => {
    e.preventDefault();
    history.push(href);
  };

  return (
    <Grow in timeout={500}>
      <Grid container spacing={4}>
        <Grid
          item
          container
          sm={12}
          lg={10}
          className={classes.gridContainerLinks}
        >
          {sections.map((section) => (
            <Link
              key={section.href}
              color={isLinkActive(section.href) ? 'secondary' : 'textPrimary'}
              onClick={(e) => handleClickLink(e, section.href)}
              href={section.href}
              className={classes.link}
            >
              {section.label}
            </Link>
          ))}
          <ButtonGroup />
        </Grid>
      </Grid>
    </Grow>
  );
};

const TopbarContentMobile = () => {
  const [, setOpenSidebar] = useContext(SidebarContext);
  const history = useHistory();

  const handleClickIsotipo = () => {
    history.push('/');
    setOpenSidebar(false);
  };

  return (
    <Grow in timeout={500}>
      <Box width="100%" display="flex" alignItems="center" justifyContent="space-between">
        <IconButton onClick={() => setOpenSidebar((prev) => !prev)}>
          <MenuIcon />
        </IconButton>
        <IsotipoAntrosport width="35px" onClick={handleClickIsotipo} style={{ cursor: 'pointer' }} />
      </Box>
    </Grow>
  );
};

const Topbar = (props) => {
  // scroll Y position where topbar will dissapear.
  const threshold = 1;
  const triggerScroll = useScrollTrigger({ threshold });
  const [, , , isMobile] = useContext(SidebarContext);
  const [breakThreshold, setBreakthreshold] = useState(false);
  const classes = useStyles();
  const breakpoint = useBreakpoint();

  useScrollPosition(({ currPos }) => {
    if (Math.abs(currPos.y) > threshold) {
      setBreakthreshold(true);
    } else {
      setBreakthreshold(false);
    }
  }, []);

  return (
    <Slide
      appear={false}
      direction="down"
      in={!triggerScroll}
      timeout={500}
    >
      <AppBar
        position={breakThreshold ? 'fixed' : 'relative'}
        color="default"
        elevation={0}
        {...props}
      >
        <Toolbar className={isMobile ? classes.toolbarMobile : classes.toolbar}>
          {isMobile && <TopbarContentMobile />}
          {!isMobile && (breakThreshold ? <TopbarContent /> : <TopbarContentLogo />)}
        </Toolbar>
      </AppBar>
    </Slide>
  );
};

export default Topbar;
