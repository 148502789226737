import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import {
  FieldWrapperCheckboxSwitchRadioButton,
  FieldWrapperCheckboxSwitchRadioButtonProps,
} from './FieldWrappers';

const FieldCheckbox = ({
  name,
  label,
  gridProps = {},
  ...props
}) => {
  return (
    <FieldWrapperCheckboxSwitchRadioButton
      name={name}
      label={label}
      gridProps={gridProps}
      labelPlacement="end"
    >
      {({ controller }) => (
        <Checkbox
          checked={controller.field.value}
          onChange={(e) => controller.field.onChange(e.target.checked)}
          color="primary"
          {...props}
        />
      )}
    </FieldWrapperCheckboxSwitchRadioButton>
  );
};

FieldCheckbox.propTypes = {
  ...FieldWrapperCheckboxSwitchRadioButtonProps,
};

export default FieldCheckbox;
