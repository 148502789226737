import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { SearchOutlined } from '@material-ui/icons';

const SearchFaqPropTypes = {
  preguntasFrecuentes: PropTypes.arrayOf(
    PropTypes.shape(
      { id: PropTypes.string.isRequired, label: PropTypes.string.isRequired, question: PropTypes.string.isRequired },
    ),
  ),
  handleChangeSearch: PropTypes.func.isRequired,
};

const SearchFaq = ({
  preguntasFrecuentes,
  handleChangeSearch,
  ...props
}) => {
  return (
    <TextField
      fullWidth
      variant="outlined"
      placeholder="Buscar en preguntas frecuentes"
      size="small"
      onChange={handleChangeSearch}
      InputProps={{
        startAdornment: <SearchOutlined style={{ marginRight: '10px', color: '#aaa' }} />,
      }}
      {...props}
    />
  );
};

SearchFaq.propTypes = SearchFaqPropTypes;

export default SearchFaq;
