import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import Layout from '../Layout';
import Login from '../Login/LoginView';
import Home from '../Home';
import Producto from '../Producto';
import ComposicionCorporal from '../ComposicionCorporal';
import AbordajeNutricional from '../AbordajeNutricional';
import Suscripciones from '../Suscripciones';
import Contactanos from '../Contactanos';
import PreguntasFrecuentes from '../PreguntasFrecuentes';
import {
  HOME,
  LOGIN,
  PRODUCTO,
  COMPOSICION_CORPORAL,
  ABORDAJE_NUTRICIONAL,
  SUSCRIPCIONES,
  CONTACTANOS,
  PREGUNTAS_FRECUENTES,
} from './routePaths';
import { useSetNavigationTitle } from '../shared/hooks';

const Routes = () => {
  useSetNavigationTitle();
  return (
    <Switch>
      <WRoute exact path={LOGIN}>
        <Login />
      </WRoute>
      <WRoute exact path={PRODUCTO}>
        <Producto />
      </WRoute>
      <WRoute exact path={COMPOSICION_CORPORAL}>
        <ComposicionCorporal />
      </WRoute>
      <WRoute exact path={ABORDAJE_NUTRICIONAL}>
        <AbordajeNutricional />
      </WRoute>
      <WRoute exact path={SUSCRIPCIONES}>
        <Suscripciones />
      </WRoute>
      <WRoute exact path={CONTACTANOS}>
        <Contactanos />
      </WRoute>
      <WRoute exact path={`${PREGUNTAS_FRECUENTES}/:faq?`}>
        <PreguntasFrecuentes />
      </WRoute>
      <WRoute path={HOME}>
        <Home />
      </WRoute>
    </Switch>
  );
};

const WRoute = ({ children, ...props }) => {
  /*
    * Wraps children with layout
    * if don't want layout just use normal route.
  */
  return (
    <Route {...props}>
      <Layout>
        {children}
      </Layout>
    </Route>
  );
};

WRoute.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Routes;
