import {
  Link, Grid, Typography, Container,
} from '@material-ui/core';
import LoginForm from './LoginForm';

const Login = () => {
  return (
    <Container maxWidth="xs">
      <Grid container spacing={1} style={{ marginBottom: '50px' }}>
        <Grid item xs={12}>
          <Typography variant="h4" style={{ fontSize: '34px' }}>¡Hola!</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h4" style={{ fontWeight: 400, fontSize: '28px' }} color="primary">
            Bienvenido a AntroSport
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Link
            variant="body1"
            color="textPrimary"
            href="/conoce_mas_aca"
            style={{ textDecoration: 'underline' }}
          >
            Conocé más acá
          </Link>
        </Grid>
      </Grid>
      <LoginForm />
    </Container>
  );
};

export default Login;
