/* eslint-disable import/prefer-default-export */

// eslint-disable-next-line max-len
const dummy = 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.';

const buildFaq = ({
  id, question, label, answer,
}) => ({
  id,
  question,
  label,
  answer,
});

export const PREGUNTAS_FRECUENTES = [
  buildFaq({
    id: 'crear-usuarios',
    question: '¿Cómo crear usuarios?',
    label: 'Crear usuarios',
    answer: `Creo mis usuarios ${dummy}`,
  }),
  buildFaq({
    id: 'administrar-usuarios',
    question: '¿Cómo administrar usuarios?',
    label: 'Administrar usuarios',
    answer: `Administro mis usuarios ${dummy}`,
  }),
  buildFaq({
    id: 'turnos',
    question: '¿Cómo sacar un turno?',
    label: 'Turnos',
    answer: `Saco mi turno ${dummy}`,
  }),
  buildFaq({
    id: 'tu-logo',
    question: '¿Cómo colocar mi logo?',
    label: 'Tu logo',
    answer: `Coloco mi logo ${dummy}`,
  }),
  buildFaq({
    id: 'seguimientos',
    question: '¿Cómo hacer un seguimiento?',
    label: 'Seguimientos',
    answer: `Hago un seguimiento ${dummy}`,
  }),
  buildFaq({
    id: 'mi-paciente',
    question: '¿Cómo cargar mis pacientes?',
    label: 'Mi paciente',
    answer: `Cargo mi paciente ${dummy}`,
  }),
];
