import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Carousel from 'react-material-ui-carousel';
import { useTheme } from '@material-ui/styles';
import { makeStyles } from '@material-ui/core';
import { useBreakpoint } from '../tools';

const useStyles = makeStyles((theme) => ({
  carousel: {
    [theme.breakpoints.up('md')]: {
      maxWidth: '100%',
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: '1000px',
    },
  },
  slideCard: {
    paddingBottom: '10px',
  },
  caption: {
    fontSize: '14px',
    padding: '10px',
  },
  navButtons: {
    backgroundColor: '#eee',
    color: 'rgb(120, 120, 120)',
    [theme.breakpoints.up('md')]: {
      width: '40px',
      height: '40px',
    },
    [theme.breakpoints.down('md')]: {
      width: '20px',
      height: '20px',
    },
  },
}));

const SlideItemPropTypes = {
  items: PropTypes.any,
};

const MultipleCarouselPropTypes = SlideItemPropTypes;

const MultipleCarousel = ({
  items = [],
  ...props
}) => {
  const theme = useTheme();
  const classes = useStyles();

  if (!items || items.length <= 0) return null;
  return (
    <Carousel
      className={classes.carousel}
      autoPlay={false}
      animation="fade"
      timeout={500}
      cycleNavigation
      navButtonsAlwaysInvisible={false}
      navButtonsAlwaysVisible
      fullHeightHover={false}
      navButtonsProps={{
        className: classes.navButtons,
      }}
      {...props}
    >
      {items.map((item) => (
        <div>{item}</div>
      ))}
    </Carousel>
  );
};

MultipleCarousel.propTypes = MultipleCarouselPropTypes;

export default MultipleCarousel;
