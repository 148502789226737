import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core';
import TitleParagraphDivider from './TitleParagraphDivider';
import PersonAvatar from './PersonAvatar';

const useStyles = makeStyles((theme) => ({
  gridAvatar: {
    justifyContent: 'flex-end',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'flex-start',
    },
  },
}));

const TitleAvatarDescriptionPropTypes = {
  title: PropTypes.string.isRequired,
  avatar: PropTypes.string.isRequired,
  avatarAlt: PropTypes.string.isRequired,
  description: PropTypes.arrayOf(PropTypes.node).isRequired,
};

const TitleAvatarDescription = ({
  title, avatar, avatarAlt, description,
}) => {
  /*
    * Used in Producto, Composicion corporal and Abordaje nutricional sections.
  */
  const classes = useStyles();

  return (
    <Grid container spacing={4}>
      <Grid
        item
        container
        xs={12}
        sm={8}
        justify="space-between"
        alignItems="center"
      >
        <Typography variant="h3" color="primary">
          {title}
        </Typography>
      </Grid>
      <Grid className={classes.gridAvatar} item container xs={12} sm={4}>
        <PersonAvatar avatar={avatar} alt={avatarAlt} />
      </Grid>
      <Grid item xs={12}>
        <TitleParagraphDivider />
      </Grid>
      <Grid container item xs={12} spacing={4}>
        <Grid item xs={12} md={6}>
          <Typography variant="body1">{description[0]}</Typography>
          <Typography variant="body1">{description[1]}</Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="body1">{description[2]}</Typography>
          <Typography variant="body1">{description[3]}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

TitleAvatarDescription.propTypes = TitleAvatarDescriptionPropTypes;

export default TitleAvatarDescription;
