import _ from 'lodash';
import { useEffect, useState } from 'react';
import { animateScroll as scroll } from 'react-scroll';
import { ROUTE_TITLE } from '../Router/routePaths';
import { useBreakpoint } from './tools';

const useSetNavigationTitle = () => {
  const { pathname } = window.location;
  const pathsJoined = pathname
    .split('/') /* separating paths */
    .filter((p) => p) /* filtering those falsy */
    .map((p) => _.capitalize(p).replace('-', ' ')) /* my-path-name --> My path name */
    .reverse() /* reversing to show first the last path */
    .join(' - ');
  useEffect(() => {
    document.title = ROUTE_TITLE[pathname] || pathsJoined;
  }, [pathname]);
};

const useIsMobile = () => {
  const breakpoint = useBreakpoint();
  const initialState = breakpoint.value <= 1;
  const [isMobile, setMobile] = useState(initialState);
  /* if screen width is equal or lower than sm the app is mobile */
  useEffect(() => {
    if (initialState) setMobile(true);
    else setMobile(false);
  }, [breakpoint.value]);

  return isMobile;
};

const MOBILE_OFFSET = -64; // topbar height mobile;
const WEB_OFFSET = -100; // topbar height web;

const useScrollState = (sections) => {
  const [activeSection, setActiveSection] = useState(sections[0]);
  const [isLastSection, setLastSection] = useState(false);
  const isMobile = useIsMobile();

  useEffect(() => {
    scroll.scrollToTop();
  }, []);

  const handleChangeActive = (active) => {
    setActiveSection(active);
    if (active === sections[sections.length - 1]) setLastSection(true);
    else setLastSection(false);
  };

  const offset = isMobile ? MOBILE_OFFSET : WEB_OFFSET;

  return {
    activeSection,
    handleChangeActive,
    isLastSection,
    offset: activeSection === sections[0] ? offset : 0,
  };
};

export {
  useSetNavigationTitle,
  useIsMobile,
  useScrollState,
};
