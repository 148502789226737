import { useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import Grid from '@material-ui/core/Grid';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import FieldText from '../shared/FormComponents/FieldText';
import { FieldButton, FieldButtonSubmit } from '../shared/FormComponents/FormButtons';
import { phoneRegExp, fakePromise } from '../shared/tools';
import Toast from '../shared/Components/Toast';

const HablemosForm = () => {
  const toastRef = useRef();
  const [isSubmitting, setSubmitting] = useState(false);
  const [formError, setFormError] = useState('');
  const defaultValues = {
    name: '',
    email: '',
    phone: '',
    subject: '',
    body: '',
  };

  const validationSchema = yup.object({
    name: yup
      .string()
      .required('El campo es requerido'),
    email: yup
      .string()
      .email('Debes ingresar un email válido.')
      .required('El campo es requerido'),
    phone: yup
      .string()
      .matches(phoneRegExp, 'El numero de teléfono no es valido'),
    subject: yup
      .string()
      .required('El campo es requerido'),
    body: yup
      .string()
      .required('El campo es requerido'),
  });

  const form = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
    mode: 'onBlur',
  });

  const handleSubmit = async (values) => {
    setSubmitting(true);
    try {
      await fakePromise({ values, fail: false });
      setFormError('');
      toastRef?.current?.toggleToastSuccess('Los datos se han enviado satisfactoriamente');
    } catch (error) {
      setFormError(error.message);
      toastRef?.current?.toggleToastError('Error al enviar los datos');
    } finally {
      setSubmitting(false);
    }
  };

  const handleClear = () => {
    form.reset();
  };

  return (
    <FormProvider {...form}>
      <form
        name="hablemos_form"
        id="hablemos_form"
        noValidate
        onSubmit={form.handleSubmit(handleSubmit)}
      >
        <Grid container spacing={4}>
          <FieldText name="name" label="Nombre y apellido" />
          <FieldText name="email" type="email" label="Email" />
          <FieldText name="phone" type="phone" label="Whatsapp" />
          <FieldText name="subject" label="Asunto" />
          <FieldText name="body" multiline rows={5} label="Comentarios" />
          <FieldButton
            label="Reestablecer"
            color="secondary"
            variant="outlined"
            gridProps={{ xs: 6, container: true, justify: 'flex-end' }}
            style={{ width: '147px', height: '40px' }}
            onClick={handleClear}
          />
          <FieldButtonSubmit
            label="Enviar"
            gridProps={{ xs: 6 }}
            color="secondary"
            variant="contained"
            style={{ width: '147px', height: '40px' }}
            isSubmitting={isSubmitting}
          />
        </Grid>
        <Toast ref={toastRef} />
      </form>
    </FormProvider>
  );
};

export default HablemosForm;
