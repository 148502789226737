import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { useTheme, makeStyles } from '@material-ui/styles';
import Laptop from './Laptop';
import { useBreakpoint } from '../tools';
import MultipleCarousel from './MultipleCarousel';
/* eslint-disable max-len */
import seguirAlPaciente from '../../assets/que-hacemos-features/1-seguir-al-paciente.png';
import cargarFormatosDeEvaluaciones from '../../assets/que-hacemos-features/2-cargar-formatos-de-evaluaciones.png';
import armarDiferentesReportes from '../../assets/que-hacemos-features/3-armar-diferentes-reportes-de-composicion-corporal.png';
import configurarHistoriaClinicaATuParecer from '../../assets/que-hacemos-features/4-configurar-historia-clinica-a-tu-parecer.png';
import adjuntarArchivosParaLaApp from '../../assets/que-hacemos-features/5-adjuntar-archivos-para-la-app.png';
import cargarReferenciasParaLaComparacion from '../../assets/que-hacemos-features/6-cargar-referencias-para-la-comprasacion.png';
import clasificarAlPacienteSegunReferencia from '../../assets/que-hacemos-features/7-clasificar-al-paciente-segun-referencia.png';
import armarSegmentosParaReportesGrupales from '../../assets/que-hacemos-features/8-armar-segementos-para-reportes-grupales.png';
import exportarPlanesDeAlimentacion from '../../assets/que-hacemos-features/9-exportar-planes-de-alimentacion.png';
import disenarPlanesDeAlimentacion from '../../assets/que-hacemos-features/10-disenar-planes-de-alimentacion.png';
import personalizarPlanes from '../../assets/que-hacemos-features/11-personalizar-planes.png';

const activities = [
  { src: seguirAlPaciente, alt: 'Seguimientos de paciente' },
  { src: cargarFormatosDeEvaluaciones, alt: 'Cargar formatos de evaluaciones' },
  { src: armarDiferentesReportes, alt: 'Armar reportes de composición corporal' },
  { src: configurarHistoriaClinicaATuParecer, alt: 'Configurar historia clínica a tu parecer' },
  { src: adjuntarArchivosParaLaApp, alt: 'Adjuntar archivos para la app' },
  { src: cargarReferenciasParaLaComparacion, alt: 'Cargar referencias para la comparación' },
  { src: clasificarAlPacienteSegunReferencia, alt: 'Clasificar al paciente segun referencia' },
  { src: armarSegmentosParaReportesGrupales, alt: 'Armar segmentos para reportes grupales' },
  { src: exportarPlanesDeAlimentacion, alt: 'Exportar planes de alimentación' },
  { src: disenarPlanesDeAlimentacion, alt: 'Diseñar planes de alimentación' },
  { src: personalizarPlanes, alt: 'Personalizar planes de alimentación' },
];

const useStyles = makeStyles((theme) => ({
  laptop: {
    width: '1200px',
    [theme.breakpoints.down('lg')]: {
      width: '1000px',
    },
    [theme.breakpoints.down('md')]: {
      width: '900px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '600px',
    },
    [theme.breakpoints.down('xs')]: {
      width: '400px',
    },
    '@media (max-width: 400px)': {
      width: '310px',
    },
  },
}));

const QueHacemos = () => {
  const classes = useStyles();
  const theme = useTheme();
  const { breakpoint } = useBreakpoint();
  const [CarouselItems, setCarouselItems] = useState([]);
  const [currentItem, setCurrentItem] = useState(activities[0]);

  useEffect(() => {
    const Laptops = activities.map(({ alt, src }) => (
      <Laptop
        className={classes.laptop}
        key={src}
        content={<img alt={alt} src={src} width="100%" height="100%" />}
      />
    ));
    setCarouselItems(Laptops);
  }, [activities]);

  const handleChangeItem = (index) => setCurrentItem(activities[index]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} container justify="center" alignItems="center">
        <Typography
          variant="h3"
          color="primary"
          style={{ marginBottom: theme.spacing(2) }}
        >
          Qué podes hacer?
        </Typography>
      </Grid>
      <Grid item xs={12} container justify="center" alignItems="center">
        <Typography variant="h6" color="primary" align="center">{currentItem.alt}</Typography>
      </Grid>
      <Grid item container xs={12} justify="center" alignItems="center">
        <MultipleCarousel items={CarouselItems} navButtonsAlwaysVisible onChange={handleChangeItem} />
      </Grid>
    </Grid>
  );
};

export default QueHacemos;
