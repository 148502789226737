import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import { useTheme } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import MobilePhone from '../shared/Components/MobilePhone';
import appleStore from '../assets/apple-store-badge.svg';
import googlePlay from '../assets/google-play-badge.svg';
import { useBreakpoint } from '../shared/tools';

const EnDondeEstesVosPodras = () => {
  const breakpoint = useBreakpoint();

  return (
    breakpoint.value >= 3 ? <EnDondeEstesVosPodrasLgUp /> : <EnDondeEstesVosPodrasLgDown />
  );
};

const EnDondeEstesVosPodrasLgUp = () => {
  const theme = useTheme();
  return (
    <Grid container spacing={4}>
      <Grid item container xs={12} lg={8} spacing={4}>
        <Grid item xs={12}>
          <HeaderDescription />
        </Grid>
        <Grid item xs={12}>
          <Divider
            style={{
              width: '100%',
              height: '3px',
              backgroundColor: theme.palette.secondary.main,
            }}
          />
        </Grid>
        <Grid item container xs={12} spacing={4}>
          <Grid item xs={6}>
            <ListItemVosPodras />
          </Grid>
          <Grid item xs={6}>
            <ListItemTusPacientesPodran />
          </Grid>
        </Grid>
        <Grid item container xs={12}>
          <StoreBadges />
        </Grid>
      </Grid>
      <Grid item container xs={12} lg={4} spacing={4} justify="center" alignItems="flex-start">
        <MobilePhone />
      </Grid>
    </Grid>
  );
};

const EnDondeEstesVosPodrasLgDown = () => {
  const theme = useTheme();
  return (
    <Grid container spacing={4} justify="center">
      <Grid item container xs={12} spacing={8}>
        <Grid item xs={12}>
          <HeaderDescription />
        </Grid>
        <Grid item xs={12}>
          <Divider
            style={{
              width: '40%',
              height: '3px',
              backgroundColor: theme.palette.secondary.main,
            }}
          />
        </Grid>
        <Grid item container xs={12} spacing={4}>
          <Grid item xs={12} md={6}>
            <ListItemVosPodras />
          </Grid>
          <Grid item xs={12} md={6}>
            <ListItemTusPacientesPodran />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={6}>
        <StoreBadges height="100%" />
      </Grid>
      <Grid
        item
        container
        xs={12}
        md={6}
        spacing={8}
        justify="center"
        alignItems="flex-start"
      >
        <MobilePhone />
      </Grid>
    </Grid>
  );
};

// eslint-disable-next-line react/prop-types
const Item = ({ children }) => {
  return (
    <Box display="flex" alignItems="center" mt={2} mb={2}>
      <CheckCircleOutlineIcon color="primary" style={{ marginRight: '10px' }} />
      {children}
      <br />
    </Box>
  );
};

const HeaderDescription = () => (
  <>
    <Typography variant="h4" color="secondary" style={{ marginBottom: '20px' }}>
      En donde estes
    </Typography>
    <Typography variant="body1">
      Ofrécele a tus pacientes un detallado seguimiento nutricional, a
      través de una app. Comunicate con ellos de manera segura, a fin de
      mejorar los resultados juntos.
    </Typography>
  </>
);

const ListItemVosPodras = () => (
  <>
    <Typography variant="h5" color="secondary">
      Vos podrás
    </Typography>
    <Typography
      variant="body2"
    >
      <Item>Seguir al paciente desde cualquier lugar.</Item>
      <Item>Cargar evaluaciones fuera del consultorio.</Item>
      <Item>Obtener reportes de composición corporal.</Item>
      <Item>Asignar turnos. (*)</Item>
    </Typography>
  </>
);

const ListItemTusPacientesPodran = () => (
  <>
    <Typography variant="h5" color="secondary">
      Tus pacientes podrán
    </Typography>
    <Typography
      variant="body2"
    >
      <Item>Seguir el progreso del tratamiento.</Item>
      <Item>Acceder a reportes y planes de alimentación.</Item>
      <Item>Solicitar un turno. (*)</Item>
    </Typography>
  </>
);

const StoreBadges = (props) => {
  const breakpoint = useBreakpoint();
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection={breakpoint.value < 1 ? 'column' : 'row'}
      {...props}
    >
      <img height="58px" src={appleStore} alt="Apple store" style={{ margin: '10px' }} />
      <img
        style={{ margin: '10px' }}
        height="58px"
        src={googlePlay}
        alt="Google Play"
      />
    </Box>
  );
};

export default EnDondeEstesVosPodras;
