/* eslint-disable import/prefer-default-export */
import {
  HOME,
  PRODUCTO,
  COMPOSICION_CORPORAL,
  ABORDAJE_NUTRICIONAL,
  SUSCRIPCIONES,
  CONTACTANOS,
  PREGUNTAS_FRECUENTES,
} from '../Router/routePaths';
import {
  HOME_SCROLL_SECTIONS,
} from '../Home/homeHelper';
import { isEmpty } from '../shared/tools';
import { COMPOSICION_CORPORAL_SCROLL_SECTIONS } from '../ComposicionCorporal/composicionCorporalHelper';
import { PRODUCTO_SCROLL_SECTIONS } from '../Producto/productoHelper';
import { ABORDAJE_NUTRICIONAL_SCROLL_SECTIONS } from '../AbordajeNutricional/abordajeNutricionalHelper';

export const sidebarRoutes = [
  { label: null, href: HOME, scrollSections: HOME_SCROLL_SECTIONS },
  { label: 'Sobre el soft', href: PRODUCTO, scrollSections: PRODUCTO_SCROLL_SECTIONS },
  { label: 'Composición corporal', href: COMPOSICION_CORPORAL, scrollSections: COMPOSICION_CORPORAL_SCROLL_SECTIONS },
  { label: 'Abordaje nutricional', href: ABORDAJE_NUTRICIONAL, scrollSections: ABORDAJE_NUTRICIONAL_SCROLL_SECTIONS },
  { label: 'Suscripciones', href: SUSCRIPCIONES },
  { label: 'Contactanos', href: CONTACTANOS },
];

export const getActualRoute = (routes = []) => {
  const filterPredicate = (route) => route.href === window.location.pathname;
  return routes.filter(filterPredicate)[0];
};

export const doSetSections = (scrollSections, setScrollState) => {
  if (isEmpty(scrollSections)) setScrollState({ activeSection: '', sections: [] });
  else if (scrollSections.length > 0) {
    // set the total amount of sections to the scroll context.
    const [firstItem] = scrollSections;
    setScrollState({
      activeSection: firstItem,
      sections: scrollSections,
    });
  }
};
