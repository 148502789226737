import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import {
  FieldWrapperProps,
  FieldWrapperTextField,
} from './FieldWrappers';
import { useIsMobile } from '../hooks';

const FieldText = ({
  name,
  label,
  required,
  gridProps = {},
  noWrapGrid,
  fullWidth = true,
  type = 'text',
  ...props
}) => {
  const isMobile = useIsMobile();
  const [focused, setFocused] = useState(false);
  console.log(isMobile);

  return (
    <FieldWrapperTextField
      name={name}
      gridProps={gridProps}
      noWrapGrid={noWrapGrid}
      focused={focused}
      required={required}
    >
      {({ controller, error }) => (
        <TextField
          type={type}
          label={label}
          fullWidth={fullWidth}
          value={controller.field.value}
          onChange={(e) => controller.field.onChange(e.target.value)}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          error={!!error}
          required={required}
          helperText={error}
          variant="outlined"
          size={isMobile ? 'small' : 'medium'}
          {...props}
        />
      )}
    </FieldWrapperTextField>
  );
};

FieldText.propTypes = {
  ...FieldWrapperProps,
  label: PropTypes.string,
  type: PropTypes.string,
  fullWidth: PropTypes.bool,

};

export default FieldText;
