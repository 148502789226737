import avatar from '../assets/avatar-nerd.svg';
import TitleAvatarDescription from '../shared/Components/TitleAvatarDescription';

const paragraphs = [
  `
    La composición corporal al igual que la intervención nutricional
    tiene diferentes matices y puede complejizarse dependiendo de las
    herramientas de trabajo que utilice cada profesional.
    #Antrosport surge como una herramienta capaz de poder conjugar en un mismo
    programa soluciones inherentes al abordaje del paciente y a la
    interpretación correcta de su composición corporal.
  `,
  `
    Luciano Spena
    creador de esta herramienta, es un reconocido especialista en su
    campo, contando con una sólida formación académica y un destacado
    desarrollo laboral en el campo práctico en varias federaciones
    deportivas de su país (futbol, hockey, tenis, básquet y vóley).
  `,
  `
    Durante más de 15 años de experiencia “en terreno” y valiéndose de
    la opinión de diferentes profesionales prestigiosos del área, logró
    conjugar diferentes variables y herramientas que permiten realizar
    valoraciones confiables para la obtención de reportes
    antropométricos, como así también para utilizar distintas
    estrategias que posibiliten la optimización de los tiempos de
    trabajo con el consultante.
  `,
  `
    Todo esto a “un solo click” y ofreciendo un modelo educativo de
    intervención nutricional muy fácil de llevar a cabo por cada uno de
    los usuarios.
  `,
];

const SobreElSoft = () => {
  return (
    <TitleAvatarDescription
      title="Sobre el soft"
      avatar={avatar}
      avatarAlt="Person sitting on a computer"
      description={paragraphs}
    />
  );
};

export default SobreElSoft;
