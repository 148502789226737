import { FormProvider, useForm } from 'react-hook-form';
import Grid from '@material-ui/core/Grid';
import {
  Divider, Link, Typography,
} from '@material-ui/core';
import FieldText from '../shared/FormComponents/FieldText';
import FieldPassword from '../shared/FormComponents/FieldPassword';
import FieldCheckbox from '../shared/FormComponents/FieldCheckbox';
import { FieldButtonSubmit, FieldButton } from '../shared/FormComponents/FormButtons';

const LoginForm = () => {
  const defaultValues = {
    email: '',
    password: '',
    remember_me: false,
  };
  const form = useForm({
    defaultValues,
    mode: 'onBlur',
  });
  const handleSubmit = async (values) => {
    console.log(values);
  };

  return (
    <FormProvider {...form}>
      <form
        noValidate
        onSubmit={form.handleSubmit(handleSubmit)}
        name="login_form"
        id="login_form"
      >
        <Grid container spacing={2}>
          <FieldText name="email" type="email" label="Email" size="small" />
          <FieldPassword name="password" label="Password" size="small" />
          <FieldCheckbox
            name="remember_me"
            label="Recordarme"
            size="small"
            gridProps={{
              xs: 5,
              item: true,
              container: true,
              justify: 'center',
            }}
          />
          <Grid item container xs={2} justify="center" alignItems="center">
            <Divider orientation="vertical" style={{ height: '21px' }} />
          </Grid>
          <Grid item xs={5} container justify="center" alignItems="center">
            <Link href="/home">
              <Typography>Olvidé la contraseña</Typography>
            </Link>
          </Grid>
          <FieldButtonSubmit
            label="Ingresar"
            style={{
              width: '100%',
              height: '40px',
              textTransform: 'uppercase',
            }}
          />
          <FieldButton
            label="Crear usuario"
            style={{
              width: '100%',
              height: '40px',
              textTransform: 'uppercase',
            }}
            color="primary"
            variant="outlined"
          />
        </Grid>
      </form>
    </FormProvider>
  );
};

export default LoginForm;
