import { useContext, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/styles';
import Divider from '@material-ui/core/Divider';
import Link from '@material-ui/core/Link';
import { SidebarContext } from '../shared/Contexts';
import { ButtonGroup } from './Topbar';

const useStyles = makeStyles(() => ({
  drawer: {
    width: 240,
    height: '100%',
  },
  drawerPaper: {
    height: '100%',
    width: 240,
  },
  drawerContent: {
    marginTop: 64,
  },
}));

const Sidebar = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const [openSidebar, setOpenSidebar, sections] = useContext(SidebarContext);
  const currentPath = window.location.pathname;

  const handleClickLink = (e, href) => {
    e.preventDefault();
    setOpenSidebar(false);
    history.push(href);
  };

  return (
    <Drawer
      className={classes.drawer}
      classes={{
        paper: classes.drawerPaper,
      }}
      open={openSidebar}
      anchor="left"
      variant="persistent"
      elevation={5}
      {...props}
    >
      <div className={classes.drawerContent}>
        <Divider light />
        <List>
          {sections.map(({ href, label }) => (
            label && (
              <Link key={href} href={href} color="textPrimary">
                <ListItem
                  href={href}
                  key={href || label}
                  onClick={(e) => handleClickLink(e, href)}
                  button
                  selected={href === currentPath}
                >
                  <ListItemText primary={label} />
                </ListItem>
              </Link>
            )
          ))}
          <ListItem>
            <ButtonGroup flexDirection="column" alignItems="flex-start" width="100%" height="120px" />
          </ListItem>
        </List>
      </div>
    </Drawer>
  );
};

export default Sidebar;
