import { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import { animateScroll as scroll, scroller, Element as ScrollElement } from 'react-scroll';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import InboxIcon from '@material-ui/icons/Inbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Navigator from './Navigator';
import Detail from './Detail';
import BackgroundContainer from '../shared/Containers/BackgroundContainer';
import { AntroSportContainerCustomizable } from '../shared/Containers/AntroSportContainer';
import fondoQueHacemos from '../assets/fondo_que_hacemos.svg';
import TitleParagraphDivider from '../shared/Components/TitleParagraphDivider';
import SearchFaq from './SearchFaq';
import { PREGUNTAS_FRECUENTES } from './preguntasFrecuentesHelper';
import { PREGUNTAS_FRECUENTES as PREGUNTAS_FRECUENTES_ROUTE } from '../Router/routePaths';
import { removeDiacritics, isNotEmpty, isEmpty } from '../shared/tools';

const FAQS_SEARCH_INITIAL_STATE = {
  loading: false,
  faqs: [],
  search: '',
  isEmpty: true,
};

const PreguntasFrecuentes = () => {
  const didMount = useRef();
  const history = useHistory();
  const { faq: faqId } = useParams();
  const [faqsSearch, setFaqsSearch] = useState(FAQS_SEARCH_INITIAL_STATE);
  const [faq] = PREGUNTAS_FRECUENTES.filter(({ id }) => faqId === id);

  const handleChangeSearch = (event) => {
    history.push(PREGUNTAS_FRECUENTES_ROUTE);
    const { value: valueSearch } = event.target;
    const filterPredicate = ({ question, answer }) => {
      const questionClean = removeDiacritics(question).toLowerCase();
      const answerClean = removeDiacritics(answer).toLowerCase();
      const valueSearchClean = removeDiacritics(valueSearch).toLowerCase();
      // search first by question then by answer.
      return (questionClean.includes(valueSearchClean) || answerClean.includes(valueSearchClean));
    };
    const faqs = PREGUNTAS_FRECUENTES.filter(filterPredicate);
    setFaqsSearch(({
      faqs, loading: true, isEmpty: isEmpty(faqs), search: valueSearch,
    }));
  };

  useEffect(() => {
    scroller.scrollTo('detail', {
      duration: 800,
      offset: -80,
      smooth: 'easeInOutQuart',
    });
  }, [faq?.id]);

  const handleClickLink = (e, href) => {
    e.preventDefault();
    history.push(href);
    setFaqsSearch(FAQS_SEARCH_INITIAL_STATE);
  };

  useEffect(() => {
    if (didMount.current) {
      setFaqsSearch((prevState) => ({ ...prevState, loading: true }));
      setTimeout(() => {
        setFaqsSearch((prevState) => ({ ...prevState, loading: false }));
      }, 2000);
    } else didMount.current = true;
  }, [faqsSearch.faqs]);

  return (
    <BackgroundContainer backgroundImage={fondoQueHacemos} maxWidth="lg">
      <AntroSportContainerCustomizable
        display="flex"
        alignItems="flex-start"
        minHeight="100vh"
        padding="40px 30px"
      >
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography color="primary" variant="h3">
              Preguntas frecuentes
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TitleParagraphDivider />
          </Grid>
          <Grid item xs={12} md={3}>
            <Navigator
              preguntasFrecuentes={PREGUNTAS_FRECUENTES}
              handleClickLink={handleClickLink}
              style={{ marginTop: '-20px' }}
            />
          </Grid>
          <Grid item xs={12} md={9}>
            <ScrollElement name="detail">
              <SearchFaq
                style={{ marginBottom: '20px' }}
                preguntasFrecuentes={PREGUNTAS_FRECUENTES}
                handleChangeSearch={handleChangeSearch}
                value={faqsSearch.search}
              />
              {isNotEmpty(faq) ? (
                <Detail title={faq.question} detailText={faq.answer} />
              ) : (
                <LoadingDetail faqsSearch={faqsSearch} />
              )}
            </ScrollElement>
          </Grid>
        </Grid>
      </AntroSportContainerCustomizable>
    </BackgroundContainer>
  );
};

const LoadingDetailPropTypes = {
  faqsSearch: PropTypes.shape({
    faqs: PropTypes.arrayOf(PropTypes.object).isRequired,
    loading: PropTypes.bool.isRequired,
    search: PropTypes.string.isRequired,
    isEmpty: PropTypes.bool.isRequired,
  }).isRequired,
};

const LoadingDetail = ({ faqsSearch }) => {
  if (faqsSearch.loading) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="200px"
      >
        <CircularProgress color="primary" size={40} />
      </Box>
    );
  }

  return !faqsSearch.isEmpty ? (
    faqsSearch.faqs.map(({ id, question, answer }) => (
      <Detail key={id} title={question} detailText={answer} />
    ))
  ) : (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      height="200px"
    >
      {faqsSearch.search && (
        <>
          <InboxIcon fontSize="large" />
          <Typography align="center">
            No se encontraron preguntas frecuentes para la búsqueda
            {' '}
            <b>{faqsSearch.search}</b>
          </Typography>
        </>
      )}
    </Box>
  );
};

LoadingDetail.propTypes = LoadingDetailPropTypes;

export default PreguntasFrecuentes;
