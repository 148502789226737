import { Element as ScrollElement } from 'react-scroll';
import PreguntasFrecuentes from '../shared/Components/PreguntasFrecuentes';
import AntroSportContainer from '../shared/Containers/AntroSportContainer';
import QueHacemos from '../shared/Components/QueHacemos';
import SobreElSoft from './SobreElSoft';
import BackgroundContainer from '../shared/Containers/BackgroundContainer';
import fondoQueHacemos from '../assets/fondo_que_hacemos.svg';
import { PRODUCTO_SCROLL_SECTIONS } from './productoHelper';
import ButtonScroll from '../shared/Components/ButtonScroll';

const Producto = () => {
  const [sobreElSoft, queHacemos, preguntasFrecuentes] = PRODUCTO_SCROLL_SECTIONS;
  return (
    <>
      <ScrollElement id={sobreElSoft}>
        <BackgroundContainer backgroundImage={fondoQueHacemos}>
          <AntroSportContainer>
            <SobreElSoft />
          </AntroSportContainer>
        </BackgroundContainer>
      </ScrollElement>
      <ScrollElement id={queHacemos}>
        <BackgroundContainer backgroundImage={fondoQueHacemos}>
          <AntroSportContainer>
            <QueHacemos />
          </AntroSportContainer>
        </BackgroundContainer>
      </ScrollElement>
      <ScrollElement id={preguntasFrecuentes}>
        <PreguntasFrecuentes />
      </ScrollElement>
      <ButtonScroll sections={PRODUCTO_SCROLL_SECTIONS} />
    </>
  );
};

export default Producto;
