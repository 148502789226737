import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import laptop from '../../assets/laptop.svg';

const Laptop = ({ content = <div>...</div>, ...props }) => {
  return (
    <Box
      display="flex"
      position="relative"
      alignItems="center"
      justifyContent="center"
      bgcolor="transparent"
      width="700px"
      {...props}
    >
      <img src={laptop} alt="Laptop" width="100%" />
      <Box
        bgcolor="#fff"
        width="75%"
        height="79.36%"
        top="8%"
        position="absolute"
      >
        {content}
      </Box>
    </Box>
  );
};

Laptop.propTypes = {
  content: PropTypes.node.isRequired,
};

export default Laptop;
