/* eslint-disable import/prefer-default-export */
import { createContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { devconsole } from '../tools';

export const ScrollContext = createContext(null);
export const SidebarContext = createContext(null);

const ScrollContextProviderPropTypes = {
  children: PropTypes.node.isRequired,
};

export const ScrollContextProvider = ({ children }) => {
  const [scrollState, setScrollState] = useState({
    activeSection: '',
    sections: [],
  });

  return (
    <ScrollContext.Provider value={[scrollState, setScrollState]}>
      {children}
    </ScrollContext.Provider>
  );
};

ScrollContextProvider.propTypes = ScrollContextProviderPropTypes;
