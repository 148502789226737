import PropTypes from 'prop-types';
import { useEffect, useState, useContext } from 'react';
import Footer from './Footer';
import Topbar from './Topbar';
import Sidebar from './Sidebar';
import { ScrollContext, SidebarContext } from '../shared/Contexts';
import { useIsMobile } from '../shared/hooks';
import ButtonScroll from '../shared/Components/ButtonScroll';
import { isNotEmpty } from '../shared/tools';
import { getActualRoute, sidebarRoutes, doSetSections } from './layoutHelper';

const Layout = ({ children }) => {
  const [, setScrollState] = useContext(ScrollContext);
  const [openSidebar, setOpensidebar] = useState(false);
  const isMobile = useIsMobile();
  const { scrollSections } = getActualRoute(sidebarRoutes) || [];

  useEffect(() => {
    /*
      * Close sidebar automatically after passing breakpoint.
    */
    if (!isMobile) setOpensidebar(false);
  }, [isMobile]);

  useEffect(() => {
    // everytime URL path changes scroll sections will update.
    doSetSections(scrollSections, setScrollState);
  }, [window.location.pathname]);

  return (
    <SidebarContext.Provider value={[openSidebar, setOpensidebar, sidebarRoutes, isMobile]}>
      <Topbar />
      {isMobile && <Sidebar />}
      {children}
      {isNotEmpty(scrollSections) && <ButtonScroll />}
      <Footer />
    </SidebarContext.Provider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
