import { useEffect } from 'react';
import { scroller, Element as SectionElement } from 'react-scroll';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import AntroSportContainer from '../shared/Containers/AntroSportContainer';
import HablemosForm from './HablemosForm';
import BackgroundContainer from '../shared/Containers/BackgroundContainer';
import fondoQueHacemos from '../assets/fondo_que_hacemos.svg';

const Contactanos = () => {
  useEffect(() => {
    scroller.scrollTo('hablemos', {
      duration: 800,
      smooth: true,
      offset: -50,
    });
  }, []);

  return (
    <SectionElement id="hablemos">
      <BackgroundContainer backgroundImage={fondoQueHacemos}>
        <AntroSportContainer maxWidth="md">
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography variant="h3" color="primary">
                ¡Hablemos!
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <HablemosForm />
            </Grid>
          </Grid>
        </AntroSportContainer>
      </BackgroundContainer>
    </SectionElement>
  );
};

export default Contactanos;
