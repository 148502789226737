import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      height: '10px',
      backgroundColor: theme.palette.primary.main,
    },
  };
});

const SectionDivider = () => {
  const classes = useStyles();
  return (
    <Divider
      classes={{
        root: classes.root,
      }}
    />
  );
};

export default SectionDivider;
