import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {
  FormControl,
  Grid,
  FormLabel,
  InputLabel,
  makeStyles,
} from '@material-ui/core';
import { useController, useFormContext } from 'react-hook-form';

const useStyles = makeStyles((theme) => ({
  labelPlacementStart: {
    marginLeft: '0px',
  },
}));

/*
*  wraps any kind of component that includes a helperText and label
*  and pass to it, with render props pattern, the
*  form, error and controller, to manage the value and onChange of
*  the mentioned input field.
*  https://react-hook-form.com/api/usecontroller
*  https://es.reactjs.org/docs/render-props.html
*/
const FieldWrapperTextField = ({
  name,
  gridProps,
  noWrapGrid = false,
  children,
}) => {
  const form = useFormContext();
  const controller = useController({
    name,
    control: form.control,
  });
  const error = form.formState.errors[name]?.message;
  const renderProps = {
    form,
    error,
    controller,
  };
  const isFunc = typeof children === 'function';
  const renderChildren = isFunc ? children(renderProps) : children;
  return (
    noWrapGrid ? (
      renderChildren
    ) : (
      <Grid item xs={12} {...gridProps}>
        {renderChildren}
      </Grid>
    )
  );
};

/*
*  wraps a component that could be only a checkbox, switch or radiobutton with <FieldWrapperTextField />
*  and manage the value of it with controller, that comes from render props, with <FormControlLabel />
*  https://react-hook-form.com/api/usecontroller
*  https://es.reactjs.org/docs/render-props.html
*  https://material-ui.com/es/api/form-control-label/
*/
const FieldWrapperCheckboxSwitchRadioButton = ({
  name,
  label,
  gridProps,
  children,
  ...props
}) => {
  const classes = useStyles();

  const isFunc = (typeof (children) === 'function');
  return (
    <FieldWrapperTextField
      name={name}
      gridProps={gridProps}
    >
      {(fieldProps) => (
        <FormControlLabel
          value={fieldProps.controller.field.value}
          control={isFunc ? children(fieldProps) : children}
          classes={{
            labelPlacementStart: classes.labelPlacementStart,
          }}
          label={label}
          labelPlacement="start"
          {...props}
        />
      )}
    </FieldWrapperTextField>
  );
};

/*
*  wraps any type of component
*  and pass to it, with render props pattern, the
*  form, error and controller, to manage the value and onChange of
*  the mentioned component and adds to it a label (form label) and helperText (for errors and validation).
*  https://react-hook-form.com/api/usecontroller
*  https://es.reactjs.org/docs/render-props.html
*/
/*
*  This components is very useful when you have to wrap anything and register it in the form.
*/
const FieldWrapperWithLabelAndHelperText = ({
  name,
  required,
  focused,
  label,
  gridProps,
  children,
}) => {
  const isFunc = (typeof (children) === 'function');

  return (
    <FieldWrapperTextField name={name} gridProps={gridProps}>
      {(props) => (
        <>
          <FormLabel
            htmlFor={name}
            focused={focused}
            error={!!props.error}
            required={required}
          >
            {label}
          </FormLabel>
          {isFunc ? children(props) : children}
          {props.error && <FormHelperText error>{props.error}</FormHelperText>}
        </>
      )}
    </FieldWrapperTextField>
  );
};

const useStylesFieldWrapperSelect = makeStyles(() => ({
  formControl: {
    top: '8px',
  },
  inputLabel: {
    top: '-8px',
  },
}));

const FieldWrapperSelect = ({
  name,
  required,
  focused,
  label,
  gridProps,
  children,
}) => {
  const isFunc = (typeof (children) === 'function');
  const classes = useStylesFieldWrapperSelect();

  return (
    <FieldWrapperTextField
      name={name}
      gridProps={gridProps}
    >
      {(props) => (
        <FormControl
          classes={{
            root: classes.formControl,
          }}
          fullWidth
        >
          <InputLabel
            classes={{
              root: classes.inputLabel,
            }}
            id={`${name}-${label}`}
            focused={focused}
            error={!!props.error}
            required={required}
          >
            {label}
          </InputLabel>
          {isFunc ? children(props) : children}
          {props.error && (
            <FormHelperText error>
              {props.error}
            </FormHelperText>
          )}
        </FormControl>
      )}
    </FieldWrapperTextField>
  );
};

const FieldWrapperProps = {
  name: PropTypes.string.isRequired,
  gridProps: PropTypes.object,
  children: PropTypes.any,
};

const FieldWrapperCheckboxSwitchRadioButtonProps = {
  ...FieldWrapperProps,
  label: PropTypes.string,
};

const FieldWrapperWithLabelAndHelperTextProps = {
  ...FieldWrapperProps,
  label: PropTypes.string,
  focused: PropTypes.bool,
  required: PropTypes.bool,
};

FieldWrapperTextField.propTypes = {
  ...FieldWrapperProps,
};

FieldWrapperCheckboxSwitchRadioButton.propTypes = {
  ...FieldWrapperCheckboxSwitchRadioButtonProps,
};

FieldWrapperWithLabelAndHelperText.propTypes = {
  ...FieldWrapperWithLabelAndHelperTextProps,
};

FieldWrapperSelect.propTypes = {
  ...FieldWrapperWithLabelAndHelperText,
};

export {
  FieldWrapperTextField,
  FieldWrapperCheckboxSwitchRadioButton,
  FieldWrapperWithLabelAndHelperText,
  FieldWrapperSelect,
  FieldWrapperProps,
  FieldWrapperCheckboxSwitchRadioButtonProps,
  FieldWrapperWithLabelAndHelperTextProps,
};
