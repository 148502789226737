import { styled } from '@material-ui/core/styles';
import { compose, flexbox, spacing } from '@material-ui/system';
import Container from '@material-ui/core/Container';

// Used to occupy 100% of the screen height depending on the screen resolution.
export const AntroSportContainerCustomizable = styled(Container)(compose(flexbox, spacing));
const AntroSportContainer = styled(AntroSportContainerCustomizable)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  width: '100%',
  minHeight: '100vh',
  padding: '40px 30px',
});

export default AntroSportContainer;
