import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import TextField from '@material-ui/core/TextField';
import { FieldWrapperTextField, FieldWrapperProps } from './FieldWrappers';

const FieldPassword = ({
  name,
  label,
  required,
  gridProps = {},
  noWrapGrid,
  fullWidth = true,
  strengthControl = false,
  ...props
}) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <>
      <FieldWrapperTextField
        name={name}
        gridProps={gridProps}
        noWrapGrid={noWrapGrid}
      >
        {({ controller, error }) => (
          <>
            <TextField
              type={showPassword ? 'text' : 'password'}
              fullWidth={fullWidth}
              value={controller.field.value}
              label={label}
              onChange={(e) => controller.field.onChange(e.target.value)}
              error={!!error}
              required={required}
              helperText={error}
              variant="outlined"
              InputProps={{
                endAdornment:
  <InputAdornment position="end">
    <IconButton
      aria-label="toggle password visibility"
      onClick={() => setShowPassword((prev) => !prev)}
      onMouseDown={(e) => e.preventDefault()}
    >
      {showPassword ? (
        <Visibility />
      ) : (
        <VisibilityOff />
      )}
    </IconButton>
  </InputAdornment>,
              }}
              {...props}
            />
          </>
        )}
      </FieldWrapperTextField>
    </>
  );
};

FieldPassword.propTypes = {
  ...FieldWrapperProps,
  label: PropTypes.string.isRequired,
};

export default FieldPassword;
