import PropTypes from 'prop-types';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import { PREGUNTAS_FRECUENTES as PREGUNTAS_FRECUENTES_ROUTE } from '../Router/routePaths';

const NavigatorPropTypes = {
  preguntasFrecuentes: PropTypes.arrayOf(
    PropTypes.shape(
      { id: PropTypes.string.isRequired, label: PropTypes.string.isRequired, question: PropTypes.string.isRequired },
    ),
  ),
  handleClickLink: PropTypes.func.isRequired,
};

const Navigator = ({
  preguntasFrecuentes,
  handleClickLink,
  ...props
}) => {
  return (
    <List {...props}>
      {preguntasFrecuentes.map(({ id, label }) => {
        const href = `${PREGUNTAS_FRECUENTES_ROUTE}/${id}`;
        return (
          <Link href={href} key={id} color="textPrimary" onClick={(e) => handleClickLink(e, href)}>
            <ListItemText primary={label} />
          </Link>
        );
      })}
    </List>
  );
};

Navigator.propTypes = NavigatorPropTypes;

export default Navigator;
