import { makeStyles } from '@material-ui/styles';
import logo from '../../assets/logo.svg';
import isotipo from '../../assets/isotipo-antrosport.svg';

const useStyles = makeStyles((theme) => ({
  isoTipo: {
    animation: '10s rotating ease infinite',
    'animation-delay': '1s',
  },
}));

const AntrosportLogo = (props) => {
  return (
    <img src={logo} alt="Antrosport logo" {...props} />
  );
};

const IsotipoAntrosport = (props) => {
  const classes = useStyles();
  return (
    <img className={classes.isoTipo} src={isotipo} alt="Antrosport isotipo" {...props} />
  );
};

export default AntrosportLogo;
export {
  IsotipoAntrosport,
};
