import React from 'react';
import PropTypes from 'prop-types';
import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

let theme = createMuiTheme({
  typography: {
    fontFamily: [
      'Quicksand',
      'sans-serif',
    ],
    h3: {
      fontSize: '48px',
      fontWeight: 900,
    },
    h4: {
      fontWeight: 900,
    },
    h5: {
      fontWeight: 900,
    },
    h6: {
      fontWeight: 900,
    },
    body1: {
      fontWeight: 500,
      lineHeight: '36px',
    },
    body2: {
      fontWeight: 500,
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        html: {
          '& ::selection': {
            background: 'rgb(0, 199, 130, 0.3)',
          },
        },
        body: {
          margin: 0,
          padding: 0,
          boxSizing: 'border-box',
          backgroundColor: '#fffff',
        },
        '&:#root': {
          // height: 'inherit',
        },
        '@keyframes floating': {
          '0%': {
            transform: 'translate(0, 0px)',
          },
          '50%': {
            transform: 'translate(0, 15px)',
          },
          '100%': {
            transform: 'translate(0, -0px)',
          },
        },
        '@keyframes rotating': {
          '0%': {
            transform: 'rotate(0deg)',
          },
          '20%': {
            transform: 'rotate(360deg)',
          },
          '100%': {
            transform: 'rotate(360deg)',
          },
        },
      },
    },
    MuiButton: {
      root: {
        borderRadius: 18,
        textTransform: 'none',
      },
      containedPrimary: {
        color: '#ffffff',
      },
      containedSecondary: {
        color: '#ffffff',
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 8,
      },
    },
  },
  palette: {
    primary: {
      main: '#00c782',
    },
    secondary: {
      main: '#00d0c4',
    },
    text: {
      primary: '#525252',
    },
  },
  zIndex: {
    appBar: 5000,
    modal: 4999,
    tooltip: 5001,
  },
  globals: {
    toolbar: {
      height: 148,
    },
  },
});

// https://material-ui.com/customization/theming/#responsivefontsizes-theme-options-theme
/*
  * Font re-sizing will impact under sm breakpoint.
*/
theme = responsiveFontSizes(theme, { breakpoints: ['xs', 'sm'] });

const Palette = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};

Palette.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Palette;
