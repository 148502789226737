/*
  * Add all the project routes here.
*/

const HOME = '/';
const LOGIN = '/login';
const PRODUCTO = '/sobre-el-soft';
const COMPOSICION_CORPORAL = '/composicion-corporal';
const ABORDAJE_NUTRICIONAL = '/abordaje-nutricional';
const SUSCRIPCIONES = '/suscripciones';
const CONTACTANOS = '/contactanos';
const PREGUNTAS_FRECUENTES = '/preguntas-frecuentes';

const ROOT_TITLE = 'Antrosport';
const ROUTE_TITLE = {
  [HOME]: ROOT_TITLE,
  [LOGIN]: `Login - ${ROOT_TITLE}`,
  [PRODUCTO]: `Producto - ${ROOT_TITLE}`,
  [COMPOSICION_CORPORAL]: `Composición corporal - ${ROOT_TITLE}`,
  [ABORDAJE_NUTRICIONAL]: `Abordaje nutricional - ${ROOT_TITLE}`,
  [SUSCRIPCIONES]: `Suscripciones - ${ROOT_TITLE}`,
  [CONTACTANOS]: `Contactanos - ${ROOT_TITLE}`,
  [PREGUNTAS_FRECUENTES]: `Preguntas frecuentes - ${ROOT_TITLE}`,
};

export {
  HOME,
  LOGIN,
  PRODUCTO,
  COMPOSICION_CORPORAL,
  ABORDAJE_NUTRICIONAL,
  SUSCRIPCIONES,
  CONTACTANOS,
  PREGUNTAS_FRECUENTES,
  ROUTE_TITLE,
  ROOT_TITLE,
};
