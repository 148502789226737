import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    maxWidth: '292px',
    height: '2px',
  },
}));

const TitleParagraphDivider = () => {
  const classes = useStyles();
  return (
    <Divider
      classes={{
        root: classes.root,
      }}
    />
  );
};

export default TitleParagraphDivider;
