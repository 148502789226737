import { Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = (props) => makeStyles((theme) => ({
  container: {
    position: 'relative',
    // https://stackoverflow.com/questions/53768260/css-pseudo-selectors-with-material-ui
    '&::before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      opacity: 1,
      backgroundImage: `url(${props.backgroundImage})`,
      backgroundSize: 'cover',
      zIndex: -1,
    },
  },
}));

// eslint-disable-next-line react/prop-types
const BackgroundContainer = ({ children, backgroundImage, ...props }) => {
  const classes = useStyles({ backgroundImage })();
  return (
    <Container className={classes.container} maxWidth={false} disableGutters {...props}>
      {children}
    </Container>
  );
};

export default BackgroundContainer;
