import { makeStyles } from '@material-ui/styles';
import mobile from '../../assets/mobile.svg';

const useStyles = makeStyles((theme) => ({
  mobilePhone: {
    'animation-name': 'floating',
    'animation-duration': '2s',
    'animation-iteration-count': 'infinite',
    'animation-timing-function': 'ease-in-out',
  },
}));

const MobilePhone = (props) => {
  const classes = useStyles();
  return (
    <img src={mobile} className={classes.mobilePhone} alt="mobile" width="350px" {...props} />
  );
};

export default MobilePhone;
