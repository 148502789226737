import { Element as ScrollElement } from 'react-scroll';
import PreguntasFrecuentes from '../shared/Components/PreguntasFrecuentes';
import QueHacemos from '../shared/Components/QueHacemos';
import Composicion from './Composicion';
import AntroSportContainer from '../shared/Containers/AntroSportContainer';
import BackgroundContainer from '../shared/Containers/BackgroundContainer';
import fondoQueHacemos from '../assets/fondo_que_hacemos.svg';
import { COMPOSICION_CORPORAL_SCROLL_SECTIONS } from './composicionCorporalHelper';
import ButtonScroll from '../shared/Components/ButtonScroll';

const ComposicionCorporal = () => {
  const [composicionCorporal, queHacemos, preguntasFrecuentes] = COMPOSICION_CORPORAL_SCROLL_SECTIONS;
  return (
    <>
      <ScrollElement id={composicionCorporal}>
        <BackgroundContainer backgroundImage={fondoQueHacemos}>
          <AntroSportContainer>
            <Composicion />
          </AntroSportContainer>
        </BackgroundContainer>
      </ScrollElement>
      <ScrollElement id={queHacemos}>
        <BackgroundContainer backgroundImage={fondoQueHacemos}>
          <AntroSportContainer>
            <QueHacemos />
          </AntroSportContainer>
        </BackgroundContainer>
      </ScrollElement>
      <ScrollElement id={preguntasFrecuentes}>
        <PreguntasFrecuentes />
      </ScrollElement>
      <ButtonScroll sections={COMPOSICION_CORPORAL_SCROLL_SECTIONS} />
    </>
  );
};

export default ComposicionCorporal;
