import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';
import { makeStyles } from '@material-ui/styles';
import TitleParagraphDivider from '../shared/Components/TitleParagraphDivider';
import SuscriptionCard from './SuscriptionCard';
import { useIsMobile } from '../shared/hooks';

const useStyles = makeStyles((theme) => ({
  title: {
    [theme.breakpoints.up('md')]: {
      alignItems: 'flex-start',
    },
    [theme.breakpoints.down('md')]: {
      alignItems: 'center',
    },
  },
}));

const SuscriptionTypes = () => {
  const isMobile = useIsMobile();
  const classes = useStyles();

  const handleCardClick = (suscriptionType) => {
    window.location.href = `https://app.antrosport.com/?tipoSuscripcion=${suscriptionType}`;
  };
  const suscriptionsInterface = [
    {
      suscription: 'basic_account',
      title: 'Cuenta básica',
      body: {
        pacientsCount: 500,
        secretariesCount: 1,
        professionalsCount: 1,
        cost: 250,
      },
    },
    {
      suscription: 'intermediate_account',
      title: 'Cuenta intermedia',
      body: {
        pacientsCount: 2000,
        secretariesCount: 1,
        professionalsCount: 2,
        cost: 400,
      },
    },
    {
      suscription: 'advanced_account',
      title: 'Cuenta avanzada',
      body: {
        pacientsCount: 5000,
        secretariesCount: 1,
        professionalsCount: 4,
        cost: 600,
      },
    },
  ];
  return (
    <Grid container spacing={4}>
      {/* Resto del código */}
      {suscriptionsInterface.map((item, idx) => (
        <Slide
          key={item.suscription}
          in
          appear
          timeout={800}
          direction={isMobile ? 'up' : 'left'}
          unmountOnExit
        >
          <Grid
            item
            container
            xs={12}
            md={4}
            justify="center"
            alignItems="center"
          >
            <SuscriptionCard {...item} onClick={() => handleCardClick(item.suscription)} />
          </Grid>
        </Slide>
      ))}
    </Grid>
  );
};

export default SuscriptionTypes;
