import { useHistory } from 'react-router-dom';
import Divider from '@material-ui/core/Divider';
import AddIcon from '@material-ui/icons/Add';
import Link from '@material-ui/core/Link';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import faqBackground from '../../assets/FAQ.png';
import AntroSportContainer from '../Containers/AntroSportContainer';
import { PREGUNTAS_FRECUENTES } from '../../PreguntasFrecuentes/preguntasFrecuentesHelper';
import { useIsMobile } from '../hooks';

const FaqRow = ({
  // eslint-disable-next-line react/prop-types
  faq, href, handleClickRow, ...props
}) => {
  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        flexDirection="row"
        padding="20px"
        height="80px"
        onClick={handleClickRow}
        style={{ cursor: 'pointer' }}
        {...props}
      >
        <Link color="textPrimary" href={href}>{faq}</Link>
        <IconButton size="small" href={href}>
          <AddIcon />
        </IconButton>
      </Box>
      <Divider />
    </>
  );
};

const PreguntasFrecuentes = (props) => {
  const history = useHistory();
  const isMobile = useIsMobile();
  const handleClickRow = (e, id) => {
    e.preventDefault();
    history.push(`/preguntas-frecuentes/${id}`);
  };

  return (
    <AntroSportContainer
      style={{ backgroundImage: `url(${faqBackground})`, flexDirection: 'column', padding: isMobile ? '16px' : '80px' }}
      maxWidth={false}
      disableGutters
      {...props}
    >
      <Typography
        variant="h4"
        style={{ color: 'white', marginBottom: '40px', textAlign: 'center' }}
      >
        Preguntas frecuentes de AntroSport
      </Typography>
      <Card elevation={0} style={{ width: '100%', maxWidth: '900px', height: 'auto' }}>
        {PREGUNTAS_FRECUENTES.map(({ question, id }) => (
          <FaqRow key={id} faq={question} href={id} handleClickRow={(e) => handleClickRow(e, id)} />
        ))}
      </Card>
    </AntroSportContainer>
  );
};

export default PreguntasFrecuentes;
