import { useState } from 'react';
import Router from './Router';
import Palette from './theme';
import { ScrollContextProvider } from './shared/Contexts';

function App() {
  return (
    <Palette>
      <ScrollContextProvider>
        <Router />
      </ScrollContextProvider>
    </Palette>
  );
}

export default App;
