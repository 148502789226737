import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const DetailPropTypes = {
  title: PropTypes.string.isRequired,
  detailText: PropTypes.string.isRequired,
};

const Detail = ({
  title,
  detailText,
  ...props
}) => {
  return (
    <Box display="flex" alignItems="flex-start" justifyContent="center" flexDirection="column" {...props}>
      <Typography variant="body1" gutterBottom style={{ fontWeight: 800, margin: '20px 0' }}>{title}</Typography>
      <Typography variant="body2" style={{ lineHeight: 2 }}>{detailText}</Typography>
    </Box>
  );
};

Detail.propTypes = DetailPropTypes;

export default Detail;
