import avatar from '../assets/avatar-nutricionista.svg';
import TitleAvatarDescription from '../shared/Components/TitleAvatarDescription';

const paragraphs = [
  `Determinar el gasto energético diario de un individuo, y aproximarlo
  al consumo calórico según su necesidad, es parte de la tarea de un
  profesional de la nutrición. De todos modos, la realidad indica que el
  proceso de contar calorías en terreno es muy engorroso, ya que el
  ajuste según requerimientos nutricionales, composición química de los
  alimentos y muchas otras cuestiones, presentan un “gris numérico” que
  complejiza la intervención.`,
  `El programa cuenta con una herramienta que te posibilitará trabajar
  bajo un formato de distribución de plato, para realizar mejores
  intervenciones educativas y optimizar los tiempos de trabajo, por su
  “dinámica” dentro de la consulta nutricional.`,
  `Este modelo cuenta con un abanico de opciones que permite adaptar
  cualquier plan nutricional a las necesidades de cada sujeto.`,
  `Por todo esto, viene a facilitar tu forma de trabajo, ya que es una
  herramienta 100% configurable tanto para la evaluación
  clínico-nutricional como para la prescripción alimentaria con cada uno
  de los consultantes.`,
];

const Abordaje = () => {
  return (
    <TitleAvatarDescription
      title="Abordaje nutricional"
      avatar={avatar}
      avatarAlt="Person holding an apple"
      description={paragraphs}
    />
  );
};

export default Abordaje;
