/* eslint-disable no-confusing-arrow */
/* eslint-disable no-use-before-define */
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core';
import pacientes from '../assets/pacientes.svg';

const suscriptionColor = (theme) => ({
  basic_account: theme.palette.primary.main,
  intermediate_account: 'orange',
  advanced_account: theme.palette.secondary.main,
});

const useStyles = (props) => makeStyles((theme) => {
  const getHeight = (width) => width * 1.375;
  return {
    card: {
      position: 'relative',
      borderTop: `30px solid ${suscriptionColor(theme)[props.suscription]}`,
      borderRadius: '27px',
      backgroundColor: 'white',
      transition: 'top 0.35s ease 0s',
      top: 0,
      '&:hover': {
        cursor: 'pointer',
        top: -20,
      },
      // proportion 1.375
      [theme.breakpoints.up('md')]: {
        width: '380px',
        height: `${getHeight(380)}px`,
      },
      [theme.breakpoints.down('md')]: {
        width: '310px',
        height: `${getHeight(310)}px`,
      },
    },
    cardHeader: {
      textAlign: 'center',
      height: '25%',
    },
    cardContent: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-start',
      height: '75%',
      padding: '20px',
    },
    cardFont: {
      fontWeight: 600,
      textTransform: 'uppercase',
      [theme.breakpoints.up('md')]: {
        fontSize: '20px',
        lineHeight: '40px',
      },
      [theme.breakpoints.down('md')]: {
        fontSize: '16px',
        lineHeight: '28px',
      },
    },
    flexBox: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
    },
    pacientesAvatar: {
      marginRight: '20px',
      [theme.breakpoints.up('md')]: {
        width: '70px',
      },
      [theme.breakpoints.down('md')]: {
        width: '60px',
      },
    },
    chip: {
      position: 'absolute',
      bottom: 10,
      left: 20,
      fontSize: '10px',
      width: '80px',
      height: '25px',
      background: 'linear-gradient(121deg, #dedede 44%, #6f6f6f 160%)',
    },
    cost: {
      color: suscriptionColor(theme)[props.suscription],
    },
  };
});

const SuscriptionCard = ({
  suscription = 'basic_account',
  title = 'Cuenta',
  onClick,
  body = {
    secretariesCount: 1,
    professionalsCount: 1,
    pacientsCount: 500,
    cost: 300,
  },
}) => {
  const classes = useStyles({ suscription })();
  return (
    <Typography component="div" variant="body2" className={classes.cardFont}>
      <Card onClick={onClick} className={classes.card}>
        <CardHeader title={title} className={classes.cardHeader} />
        <Divider light />
        <CardContent className={classes.cardContent}>
          <Grid container spacing={2}>
            <Grid item xs={12} className={classes.flexBox}>
              <div>{pluralizeSecretaries(body.secretariesCount)}</div>
              <div>+</div>
              <div>{pluralizeProfessionals(body.professionalsCount)}</div>
            </Grid>
            <Grid item xs={12} className={classes.flexBox} style={{ flexDirection: 'row' }}>
              <img src={pacientes} alt="Pacientes" className={classes.pacientesAvatar} />
              {pluralizePacients(body.pacientsCount)}
            </Grid>
            <Grid item xs={12} className={classes.flexBox}>
              <div>
                <Typography variant="h5" className={classes.cost}>
                  {`U$D${body.cost}`}
                </Typography>
              </div>
              <div>
                <Typography variant="body2" className={classes.cost}>
                  Cada año
                </Typography>
              </div>
            </Grid>
          </Grid>
          <Chip className={classes.chip} label="+ Upgrade" />
        </CardContent>
      </Card>
    </Typography>
  );
};

const pluralizeSecretaries = (amount) => amount === 1 ? `${amount} Secretario/a` : `${amount} Secretarios/as`;
const pluralizeProfessionals = (amount) => amount === 1 ? `${amount} Profesional` : `${amount} Profesionales`;
const pluralizePacients = (amount) => amount === 1 ? `${amount} Paciente` : `incl/${amount} Pacientes`;

SuscriptionCard.propTypes = {
  suscription: PropTypes.oneOf([
    'basic_account',
    'intermediate_account',
    'advanced_account',
  ]).isRequired,
  onClick: PropTypes.func, // Define onClick como una función
  title: PropTypes.string.isRequired,
  body: PropTypes.shape({
    secretariesCount: PropTypes.number.isRequired,
    professionalsCount: PropTypes.number.isRequired,
    pacientsCount: PropTypes.number.isRequired,
    cost: PropTypes.number.isRequired,
  }),
};

export default SuscriptionCard;
