import { useState, useImperativeHandle, forwardRef } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

const Toast = forwardRef((props, ref) => {
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState('success');
  const [message, setMessage] = useState('');

  const toggleToast = () => {
    setOpen((prev) => !prev);
  };

  const toggleToastSuccess = (msg) => {
    toggleToast();
    setSeverity('success');
    setMessage(msg);
  };

  const toggleToastError = (msg) => {
    toggleToast();
    setSeverity('error');
    setMessage(msg);
  };

  useImperativeHandle(ref, () => ({
    toggleToastSuccess,
    toggleToastError,
  }));

  return (
    <Snackbar
      open={open}
      autoHideDuration={4000}
      onClose={toggleToast}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      {...props}
    >
      <Alert
        variant="filled"
        onClose={toggleToast}
        severity={severity}
        ref={ref}
      >
        {message}
      </Alert>
    </Snackbar>
  );
});

export default Toast;
