import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  logo: {
    width: '100%',
    height: '20px',
    fontFamily: 'Rockwell',
    fontSize: '14px',
    fontWeight: 800,
    textAlign: 'center',
    color: '#15506f',
    textTransform: 'uppercase',
    overflow: 'visible',
  },
}));

const CampingDesignDevStudio = () => {
  const classes = useStyles();
  return (
    <div className={classes.logo}>
      Camping design & dev studio
    </div>
  );
};

export default CampingDesignDevStudio;
