import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';

const FieldButton = ({
  gridProps,
  label,
  children,
  ...props
}) => {
  return (
    <Grid item xs={12} {...gridProps}>
      <Button
        variant="contained"
        color="default"
        disableElevation
        {...props}
      >
        {label || children}
      </Button>
    </Grid>
  );
};

const FieldButtonSubmit = ({
  gridProps,
  isSubmitting,
  label = 'Submit',
  ...props
}) => {
  return (
    <FieldButton
      gridProps={gridProps}
      color="primary"
      disabled={isSubmitting}
      type="submit"
      {...props}
    >
      {isSubmitting ? (
        <CircularProgress
          size="30px"
          color="secondary"
        />
      ) : label}
    </FieldButton>
  );
};

const FieldButtonPropTypes = {
  gridProps: PropTypes.object,
  label: PropTypes.string,
};

FieldButton.propTypes = {
  ...FieldButtonPropTypes,
};

FieldButtonSubmit.propTypes = {
  ...FieldButtonPropTypes,
  isSubmitting: PropTypes.bool,
};

export {
  FieldButton,
  FieldButtonSubmit,
};
