import { Element as ScrollElement } from 'react-scroll';
import PreguntasFrecuentes from '../shared/Components/PreguntasFrecuentes';
import QueHacemos from '../shared/Components/QueHacemos';
import Abordaje from './Abordaje';
import AntroSportContainer from '../shared/Containers/AntroSportContainer';
import BackgroundContainer from '../shared/Containers/BackgroundContainer';
import fondoQueHacemos from '../assets/fondo_que_hacemos.svg';
import { ABORDAJE_NUTRICIONAL_SCROLL_SECTIONS } from './abordajeNutricionalHelper';
import ButtonScroll from '../shared/Components/ButtonScroll';

const AbordajeNutricional = () => {
  const [abordajeNutricional, queHacemos, preguntasFrecuentes] = ABORDAJE_NUTRICIONAL_SCROLL_SECTIONS;

  return (
    <>
      <ScrollElement id={abordajeNutricional}>
        <BackgroundContainer backgroundImage={fondoQueHacemos}>
          <AntroSportContainer>
            <Abordaje />
          </AntroSportContainer>
        </BackgroundContainer>
      </ScrollElement>
      <ScrollElement id={queHacemos}>
        <BackgroundContainer backgroundImage={fondoQueHacemos}>
          <AntroSportContainer>
            <QueHacemos />
          </AntroSportContainer>
        </BackgroundContainer>
      </ScrollElement>
      <ScrollElement id={preguntasFrecuentes}>
        <PreguntasFrecuentes />
      </ScrollElement>
      <ButtonScroll sections={ABORDAJE_NUTRICIONAL_SCROLL_SECTIONS} />
    </>
  );
};

export default AbordajeNutricional;
