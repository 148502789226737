import { useHistory } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import InstagramIcon from '@material-ui/icons/Instagram';
import YoutubeIcon from '@material-ui/icons/YouTube';
import WhatsappIcon from '@material-ui/icons/WhatsApp';
import TwitterIcon from '@material-ui/icons/Twitter';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/styles';
import AntrosportLogo from '../shared/Components/AntrosportLogo';
import { useBreakpoint } from '../shared/tools';
import CampingDesignDevStudio from '../shared/Components/CampingDesignDevStudio';
import background from '../assets/fondo_footer.svg';

const useStyles = makeStyles((theme) => ({
  footer: {
    width: '100%',
    padding: '50px',
    backgroundSize: 'cover',
    position: 'relative',
    // https://stackoverflow.com/questions/53768260/css-pseudo-selectors-with-material-ui
    '&::before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      opacity: 1,
      background: '#fffff',
      backgroundImage: `url(${background})`,
      backgroundSize: 'cover',
      zIndex: -1,
    },
  },
  gridContainer: {
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'center',
    },
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
  icon: {
    borderRadius: '100%',
    border: '1px solid black',
  },
  gridContainerSections: {
    [theme.breakpoints.up('sm')]: {
      alignItems: 'center',
    },
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
      textAlign: 'center',
    },
  },
}));

const section1 = [
  { label: 'Producto', href: '/sobre-el-soft' },
  { label: 'Composición corporal', href: '/composicion-corporal' },
  { label: 'Abordaje nutricional', href: '/abordaje-nutricional' },
  { label: 'Suscripciones', href: '/suscripciones' },
  { label: 'Contactanos', href: '/contactanos' },
];

const section2 = [
  // { label: 'Registrate', href: '/registrarte' },
  { label: 'Inicia sesión', href: '/iniciar-sesion' },
  { label: 'Todos los derechos reservados 2021' },
];

const Sections = ({ links }) => {
  const history = useHistory();

  const handleClickLink = (e, href) => {
    e.preventDefault();
    history.push(href);
  };

  return links.map(({ href, label }) => (
    <Box mt={2} key={href || label}>
      <Link
        href={href || null}
        color="textPrimary"
        variant="body1"
        style={{ fontWeight: 600 }}
        onClick={(e) => handleClickLink(e, href)}
      >
        {label}
      </Link>
      <br />
    </Box>
  ));
};

// eslint-disable-next-line react/prop-types
const IconWrapper = ({ Icon }) => {
  return (
    <IconButton
      size="small"
      color="secondary"
      style={{ border: '1px solid', margin: '10px' }}
    >
      <Icon />
    </IconButton>
  );
};

const Footer = () => {
  const classes = useStyles();
  const breakpoint = useBreakpoint();
  return (
    <Container className={classes.footer} disableGutters maxWidth={false}>
      <Grid container spacing={6}>
        <Grid
          item
          container
          md={4}
          lg={4}
          xs={12}
          className={classes.gridContainer}
        >
          <AntrosportLogo width="220px" />
        </Grid>
        <Grid
          item
          container
          xs={12}
          md={4}
          lg={4}
          direction="column"
          className={classes.gridContainerSections}
        >
          {breakpoint.value <= 1 ? (
            <Sections links={section1} />
          ) : (
            <Box textAlign="left">
              <Sections links={section1} />
            </Box>
          )}
        </Grid>
        <Grid
          item
          container
          md={4}
          lg={4}
          xs={12}
          direction="column"
          className={classes.gridContainerSections}
        >
          {breakpoint.value <= 1 ? (
            <>
              <Sections links={section2} />
              <Box mt={8} display="flex" justifyContent="center" alignItems="center">
                <IconWrapper Icon={InstagramIcon} />
                <IconWrapper Icon={YoutubeIcon} />
                <IconWrapper Icon={WhatsappIcon} />
                <IconWrapper Icon={TwitterIcon} />
              </Box>
              <Box mt={2} display="flex" justifyContent="center" alignItems="center">
                <CampingDesignDevStudio />
              </Box>
            </>
          ) : (
            <Box textAlign="left">
              <Sections links={section2} />
              <Box mt={2} display="flex" justifyContent="center" alignItems="center">
                <IconWrapper Icon={InstagramIcon} />
                <IconWrapper Icon={YoutubeIcon} />
                <IconWrapper Icon={WhatsappIcon} />
                <IconWrapper Icon={TwitterIcon} />
              </Box>
              <Box mt={2} display="flex" justifyContent="center" alignItems="center">
                <CampingDesignDevStudio />
              </Box>
            </Box>
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

export default Footer;
