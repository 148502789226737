import {
  useEffect, useState, useRef, useLayoutEffect,
} from 'react';
import { useTheme } from '@material-ui/core';
import { throttle } from 'lodash';
import { useParams } from 'react-router-dom';

/* eslint-disable no-useless-escape */
function slugify(string) {
  const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·_,:;';
  const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------';
  const p = new RegExp(a.split('').join('|'), 'g');

  return string.toString()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, '-and-') // Replace & with 'and'
    // .replace(/[^\w\-]+/g, '') // Remove all non-word characters
    .replace(/\-\-+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text
}

const convertKeyToReadable = (key) => {
  const readable = key
    .charAt(0).toUpperCase() + key.slice(1)
    .split('-')
    .join(' ');

  return readable;
};

const convertReadableToKey = (readable) => {
  const wordsJoined = readable
    .split(' ')
    .map((word) => word.toLowerCase())
    .join('-');

  return wordsJoined.charAt(0).toLowerCase() + wordsJoined.slice(1);
};

const usePromiseToState = ({ promise, deps = [] }) => {
  const initialState = { data: [], loading: true, error: '' };
  const [items, setItems] = useState(initialState);
  const [dummyCount, setDummyCount] = useState(0);

  const reload = () => {
    setDummyCount(dummyCount + 1);
  };

  useEffect(async () => {
    setItems(initialState);
    try {
      const data = await promise();
      if (Array.isArray(data)) setItems({ data, loading: false, error: '' });
      else setItems({ data: [data], loading: false, error: '' });
    } catch (error) {
      setItems({ data: [], loading: false, error: error.toString() });
    }
  }, [dummyCount, ...deps]);

  return [items, setItems, reload];
};

const useBreakpoint = () => {
  const theme = useTheme();
  /* eslint-disable-next-line consistent-return */
  const getDeviceConfig = (width) => {
    const { values } = theme.breakpoints;
    if (width < values.sm) {
      return { breakpoint: 'xs', value: 0 };
      /* eslint-disable-next-line no-else-return */
    } else if (width >= values.sm && width < values.md) {
      return { breakpoint: 'sm', value: 1 };
    } else if (width >= values.md && width < values.lg) {
      return { breakpoint: 'md', value: 2 };
    } else if (width >= values.lg && width < values.xl) {
      return { breakpoint: 'lg', value: 3 };
    } else if (width >= values.xl) {
      return { breakpoint: 'xl', value: 4 };
    }
  };

  const [breakPoint, setBreakPoint] = useState(() => getDeviceConfig(window.innerWidth, theme));

  useEffect(() => {
    const calcInnerWidth = throttle(() => {
      setBreakPoint(getDeviceConfig(window.innerWidth, theme));
    }, 200);
    window.addEventListener('resize', calcInnerWidth);
    return () => window.removeEventListener('resize', calcInnerWidth);
  }, []);

  return breakPoint;
};

const useBreadcrumb = () => {
  const pathname = window.location.pathname;
  const { id } = useParams();
  const [, ...paths] = pathname
    .split('/')
    // filtering the ids because aren't showable
    .filter((path) => path !== id)
    .map((path) => ({ label: convertKeyToReadable(path), href: `/${path}` }));
  return [
    { label: 'Home', href: '/home' },
    ...paths,
  ];
};

const log = (type = 'log', ...props) => {
  if (process.env.NODE_ENV === 'development') {
    console[type](...props);
  }
};

const table = (...props) => {
  if (process.env.NODE_ENV === 'development') {
    console.table(props);
  }
};

const historyPushSlugify = (path, history) => {
  history.push(slugify(path));
};

const devconsole = (...props) => {
  if (process.env.NODE_ENV === 'development') {
    console.log(props);
  }
};

const isEmpty = (obj) => {
  if (!obj || Object.entries(obj).length <= 0) return true;
  return false;
};

const isNotEmpty = (obj) => !isEmpty(obj);

const fakeText = (amount = 5) => {
  const text = `Eligendi et temporibus dolores quia nisi dolor dolor quo explicabo.
  Corporis explicabo cum perspiciatis et non qui eligendi neque perferendis.
  Doloremque ratione eaque molestias nam delectus. Modi fugit exercitationem ut.
  Quo reiciendis nihil eum quia ratione quam ipsa. Sed nemo maiores illo est.
  Occaecati et eligendi. Non aut blanditiis non. Illo iure alias sed occaecati fugiat.
  Veniam voluptas eum aperiam dolor et quia id. Quasi eos sequi autem illum.
  Ipsam labore distinctio est officiis laborum perferendis molestias sapiente reiciendis.
  Consequatur magni vel iure aspernatur. Nihil aliquam aperiam nesciunt quam cupiditate quisquam laboriosam nemo aut.
  Dolor odit facere. Nihil vitae exercitationem quae.`;
  const textArr = [];
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < amount; i++) {
    textArr.push(text);
  }

  return textArr.join('\n');
};

const isBrowser = typeof window !== 'undefined';

function getScrollPosition({ element, useWindow }) {
  if (!isBrowser) return { x: 0, y: 0 };

  const target = element ? element.current : document.body;
  const position = target.getBoundingClientRect();

  return useWindow
    ? { x: window.scrollX, y: window.scrollY }
    : { x: position.left, y: position.top };
}

// https://dev.to/n8tb1t/tracking-scroll-position-with-react-hooks-3bbj
function useScrollPosition(effect, deps, element, useWindow, wait) {
  const position = useRef(getScrollPosition({ useWindow }));

  let throttleTimeout = null;

  const callBack = () => {
    const currPos = getScrollPosition({ element, useWindow });
    effect({ prevPos: position.current, currPos });
    position.current = currPos;
    throttleTimeout = null;
  };

  useLayoutEffect(() => {
    const handleScroll = () => {
      if (wait) {
        if (throttleTimeout === null) {
          throttleTimeout = setTimeout(callBack, wait);
        }
      } else {
        callBack();
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, deps);
}

const fakePromise = async ({ values, msecs = 1000, fail = false }) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (fail) reject(new Error('Error'));
      else resolve(values);
    }, msecs);
  });
};

/* eslint-disable-next-line max-len */
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const removeDiacritics = (text) => {
  // Elimina los diacríticos de un texto (ES6)
  return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};

export {
  slugify,
  usePromiseToState,
  useBreakpoint,
  useBreadcrumb,
  historyPushSlugify,
  convertKeyToReadable,
  convertReadableToKey,
  devconsole,
  isEmpty,
  isNotEmpty,
  fakeText,
  useScrollPosition,
  phoneRegExp,
  fakePromise,
  removeDiacritics,
};
