import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grow from '@material-ui/core/Grow';
import { makeStyles } from '@material-ui/styles';
import Laptop from '../shared/Components/Laptop';
import pantalla from '../assets/pantalla.svg';

const useStyles = makeStyles((theme) => ({
  box: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  gridSeguimientoEstrategias: {
    [theme.breakpoints.up('md')]: {
      justify: 'flex-start',
      alignItems: 'flex-start',
    },
    [theme.breakpoints.down('md')]: {
      justify: 'center',
      alignItems: 'center',
    },
  },
  laptop: {
    [theme.breakpoints.up('md')]: {
      'animation-name': 'floating',
      'animation-duration': '5s',
      'animation-iteration-count': 'infinite',
      'animation-timing-function': 'ease-in-out',
    },
  },
  laptopBox: {
    width: '800px',
    padding: theme.spacing(1),
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      width: '700px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '600px',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  boxSeguimientoEstrategias: {
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      width: 'calc(100% - 700px)',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
}));

const LaptopScreen = () => {
  return (
    <img src={pantalla} width="100%" height="100%" alt="Laptop screen" />
  );
};

const SeguimientoYEstrategias = () => {
  const classes = useStyles();

  return (
    <Box display="flex" className={classes.box}>
      <Grow in timeout={2000}>
        <Box className={classes.laptopBox}>
          <Laptop className={classes.laptop} width="100%" content={<LaptopScreen />} />
        </Box>
      </Grow>
      <Box className={classes.boxSeguimientoEstrategias}>
        <Typography variant="h3" color="primary">
          Seguimiento y estrategias inteligentes
        </Typography>
        <Typography
          variant="body1"
          style={{ marginTop: '30px' }}
        >
          <Typography component="span" style={{ fontWeight: '900' }}>#Antrosport </Typography>
          te permitirá llevar una detallada evaluación y seguimiento de la
          composición corporal de tus pacientes, configurar los reportes que
          desees entregarles y diseñar la estrategia nutricional para la
          intervención adecuada.
        </Typography>
        <Typography variant="body1" style={{ marginTop: '30px' }}>
          Podrás acceder fácilmente a los informes antropométricos y planes de
          alimentación desde cualquier dispositivo, tanto vos como tus pacientes.
        </Typography>
      </Box>
    </Box>
  );
};

export default SeguimientoYEstrategias;
