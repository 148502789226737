import avatar from '../assets/avatar-profesor.svg';
import TitleAvatarDescription from '../shared/Components/TitleAvatarDescription';

const paragraphs = [
  `La individualidad que requiere la clínica hoy día nos obliga a ser
  cada vez más precisos con la información de la composición corporal,
  ya que nos permite tomar mejores decisiones en la dirección de la
  intervención nutricional elegida.`,
  `Asimismo, el orden de prioridades para el diagnóstico en el terreno deportivo
  es muy diferente y quizás, un tanto más complejo a lo que se observa en la clínica.
  Las ciencias del deporte han instaurado en los últimos años la necesidad de la
  especificidad en el trabajo con cada atleta, a partir de las
  referencias del deporte en cuestión e intentando adaptarlas al
  contexto de cada uno.`,
  `Por esta razón, consideramos determinante que cualquier profesional
  pueda realizar un análisis de la composición corporal adecuado y
  pertinente. El disponer de varios métodos de evaluación nos permite
  contar con diferentes valoraciones y con un seguimiento más detallado
  de la composición corporal, y así poder obtener una mejor
  reproducibilidad de los datos, independientemente del campo de
  trabajo.`,
  `Además, #Antrosport es una herramienta que permite tener
  diferentes tipos de reportes, para intentar aproximarnos a un mejor
  diagnóstico y hacerlo un tanto más sencillo.`,
];

const Composicion = () => {
  return (
    <TitleAvatarDescription
      title="Composición corporal"
      avatar={avatar}
      avatarAlt="Profesor teaching"
      description={paragraphs}
    />
  );
};

export default Composicion;
