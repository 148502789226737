import { Element as ScrollElement } from 'react-scroll';
import SeguimientoYEstrategias from './SeguimientoYEstrategias';
import EnDondeEstesVosPodras from './EnDondeEstesVosPodras';
import PreguntasFrecuentes from '../shared/Components/PreguntasFrecuentes';
import AntroSportContainer from '../shared/Containers/AntroSportContainer';
import BackgroundContainer from '../shared/Containers/BackgroundContainer';
import fondo from '../assets/fondo.svg';
import SectionDivider from '../shared/Components/SectionDivider';
import { HOME_SCROLL_SECTIONS } from './homeHelper';
import ButtonScroll from '../shared/Components/ButtonScroll';

const Home = () => {
  const [seguimiento, enDondeEstes, preguntasFrecuentes] = HOME_SCROLL_SECTIONS;
  return (
    <>
      <ScrollElement id={seguimiento}>
        <BackgroundContainer backgroundImage={fondo}>
          <AntroSportContainer maxWidth="xl">
            <SeguimientoYEstrategias />
          </AntroSportContainer>
        </BackgroundContainer>
      </ScrollElement>
      <ScrollElement id={enDondeEstes}>
        <SectionDivider />
        <AntroSportContainer>
          <EnDondeEstesVosPodras />
        </AntroSportContainer>
      </ScrollElement>
      <ScrollElement id={preguntasFrecuentes}>
        <PreguntasFrecuentes />
      </ScrollElement>
      <ButtonScroll sections={HOME_SCROLL_SECTIONS} />
    </>
  );
};

export default Home;
