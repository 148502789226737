import Button from '@material-ui/core/Button';
import ArrowDown from '@material-ui/icons/ArrowDownward';
import ArrowUp from '@material-ui/icons/ArrowUpward';
import { makeStyles } from '@material-ui/core';
import { Link } from 'react-scroll';
import { useScrollState } from '../hooks';

const useStyles = makeStyles((theme) => ({
  button: {
    position: 'fixed',
    zIndex: 10,
    bottom: 10,
    right: 10,
    padding: 0,
    width: '60px',
    height: '30px',
    '& svg': {
      width: '100%',
    },
  },
}));

const ButtonScroll = ({
  sections = [],
}) => {
  const {
    activeSection, handleChangeActive, offset, isLastSection,
  } = useScrollState(sections);
  const classes = useStyles();

  return (
    sections.map((section) => {
      return (
        <Link
          key={section}
          to={section}
          activeClass="active"
          onSetActive={handleChangeActive}
          duration={500}
          offset={offset}
          smooth
          spy
          // hide links that aren't the next one because we
          // dont wan't to render all links, only the next one.
          style={{ display: sections[isLastSection ? 0 : sections.indexOf(activeSection) + 1] !== section && 'none' }}
        >
          <Button
            className={classes.button}
            color="secondary"
            variant="contained"
          >
            {isLastSection ? <ArrowUp /> : <ArrowDown />}
          </Button>
        </Link>
      );
    })
  );
};

export default ButtonScroll;
