import { Element as SectionElement } from 'react-scroll';
import SuscriptionTypes from './SuscriptionsTypes';
import AntroSportContainer from '../shared/Containers/AntroSportContainer';
import PreguntasFrecuentes from '../shared/Components/PreguntasFrecuentes';
import BackgroundContainer from '../shared/Containers/BackgroundContainer';
import fondoQueHacemos from '../assets/fondo_que_hacemos.svg';
import { SUSCRIPCIONES_SCROLL_SECTIONS } from './suscripcionesHelper';
import ButtonScroll from '../shared/Components/ButtonScroll';

const Suscripciones = () => {
  const [suscripciones, preguntasFrecuentes] = SUSCRIPCIONES_SCROLL_SECTIONS;
  return (
    <>
      <SectionElement id={suscripciones}>
        <BackgroundContainer backgroundImage={fondoQueHacemos}>
          <AntroSportContainer>
            <SuscriptionTypes />
          </AntroSportContainer>
        </BackgroundContainer>
      </SectionElement>
      <SectionElement id={preguntasFrecuentes}>
        <PreguntasFrecuentes />
      </SectionElement>
      <ButtonScroll sections={SUSCRIPCIONES_SCROLL_SECTIONS} />
    </>
  );
};

export default Suscripciones;
