import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  img: {
    [theme.breakpoints.up('sm')]: {
      width: '150px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100px',
    },
    [theme.breakpoints.down('xxs')]: {
      display: 'none',
    },
  },
}));

const PersonAvatar = ({ avatar, alt, ...props }) => {
  const classes = useStyles();
  return (
    <img
      className={classes.img}
      src={avatar}
      alt={alt}
      {...props}
    />
  );
};

PersonAvatar.propTypes = {
  avatar: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
};

export default PersonAvatar;
